<template>
  <div>
    <div class="agreementtext_box">
      <div class="update_tips"
           v-if="tipsFlag">{{$t('toast.agreementTips1')}}</div>
      <div class="agreementtext"
           v-html="content">
        {{content}}
      </div>
    </div>
    <!-- <p class="tips">{{$t('toast.agreementTips2')}}</p> -->
    <!-- <div class="confirm_btn_list">
      <span class="fl confirm_btn cancel"
            @click="cancelTip">{{$t('toast.agreementTips3')}}</span>
      <span class="fl confirm_btn confirm"
            @click="clickConfirm">{{$t('toast.agreementTips4')}}</span>
    </div> -->
    <div>

    </div>
  </div>
</template>

<script>
import { agreement } from '@/api/init'
import {
  setLocalStorage,
  getLocalStorage,
  getSessionStorage,
  setSessionStorage,
} from '@/utils/stage'

export default {
  data() {
    return {
      content: '',
      tipsFlag: false,
      scrollBottom: false,
      dataArr: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const configData = JSON.parse(getSessionStorage('message'))
      const msg = Object.assign(JSON.parse(JSON.stringify(configData)), {
        type: 1,
      })
      agreement(msg).then((res) => {
        this.content = this.place(res.data.content)
      })
      if (parseInt(getLocalStorage('userAgreementFlag')) === 2) {
        this.tipsFlag = true
      }
    },
    place(data) {
      const regplaceData = this.regplace(data)
      const content_string = this.placechange(regplaceData)
      return content_string
    },
    nextTick() {
      const el = document.querySelector('.agreementtext_box')
      const offsetHeight = el.offsetHeight
      el.onscroll = () => {
        const scrollTop = el.scrollTop
        const scrollHeight = el.scrollHeight
        if (offsetHeight + scrollTop - scrollHeight + 5 >= 0) {
          this.scrollBottom = true
        }
      }
    },
    placechange(data) {
      const content = data
        .replace(/<e>/g, '<div  style="width:100%;text-align:center;">')
        .replace(/<\/e>/g, '</div>')
        .replace(/<dt>/g, '<a>')
        .replace(/<\/dt>/g, '</a>')
        .replace(/<red>/g, '<span style="color:red;">')
        .replace(/<\/red>/g, '</span>')
        .replace(/<b>/g, '<stong style=" font-weight: 700;">')
        .replace(/<\/b>/g, '</stong>')
        .replace(/\n/g, '</br>')
      return content
    },

    regplace(data) {
      let dataMsg = data
      let dataArr = []
      // var n = data.match(/<dt>([\s\S]*?)<\/dd>/g)
      // var n = data.match(/<dt>(.*?)<\/dd>/g)
      var n = dataMsg.match(/<dt>([\s\S]*?)<\/dt><dd>([\s\S]*?)<\/dd>/g)
      for (const i in n) {
        const a = n[i].match(/<dt>([\s\S]*?)<\/dt>/)
        const b = n[i].match(/<dd>([\s\S]*?)<\/dd>/)
        const ahmtr = `<a href="#/agreementInfo?text=${i}" style="text-decoration:underline;color:blue;">${a[1]} </a>`
        dataMsg = dataMsg.replace(a[0], ahmtr)
        dataMsg = dataMsg.replace(b[0], '')
        const arr = {
          name: a[1],
          value: b[1],
        }
        dataArr.push(arr)
      }
      setSessionStorage('agreement_info', JSON.stringify(dataArr))
      return dataMsg
    },
    // getstarend (abc) {
    //   const reg2 = /^([\s\S]+?)<dt>[\s\S]+<\/dd>([\s\S]+?)$/
    //   const result = abc.match(reg2)
    //   return result
    // },
    clickConfirm() {
      // if (!this.scrollBottom) {
      //   this.$toast(this.$t('toast.agreementTips5'))
      // } else {
      this.$getburiedPoint('agree_user_terms', '0-006')
      setLocalStorage('userAgreementFlag', 1)
      // 判断隐私协议
      const SessionConfig = JSON.parse(getSessionStorage('configInit'))
      setLocalStorage('configInit', JSON.stringify(SessionConfig))
      this.$router.push({ path: '/phonelogin' })
    },
    cancelTip() {
      this.$toast(this.$t('toast.agreementTips6'))
    },
  },
}
</script>
<style lang="scss" scope>
@media (min-width: 1600px) {
  .update_tips {
    font-size: 18px;
    font-weight: 700;
    color: #343434;
  }
  .agreementtext_box {
    width: 1260px;
    height: 400px;
    background: #fbfbfb;
    margin: 0 auto;
    // margin-top: 16px;
    // padding-bottom: 20px;
    // box-sizing: border-box;
    overflow-y: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
      scrollbar-arrow-color: red;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      scrollbar-arrow-color: red;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .agreementtext {
    font-size: 18px;
    padding: 15px;
    box-sizing: border-box;
    br {
      text-indent: 2em;
    }
  }
  .tips {
    font-size: 18px;
    font-family: SimHei;
    font-weight: 400;
    line-height: 27px;
    color: #a8a8a8;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 6px;
  }
  .confirm_btn_list {
    width: 720px;
    margin: 0 auto;
    margin-top: 20px;
    .confirm_btn {
      line-height: 62px;
      text-align: center;
      border-radius: 6px;
      font-size: 26px;
      cursor: pointer;
    }
    .cancel {
      width: 238px;
      height: 58px;
      border: 2px solid #ff7391;
      color: #ff7391;
    }
    .confirm {
      width: 242px;
      height: 62px;
      background: #ff7391;
      color: #fff;
      margin-left: 233px;
    }
  }
  .agreement_a {
    color: blue;
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .update_tips {
    font-size: 16px;
    font-weight: 700;
    color: #343434;
  }
  .agreementtext_box {
    width: 1260 * 0.8px;
    height: 400 * 0.8px;
    background: #fbfbfb;
    margin: 0 auto;
    // margin-top: 16 * 0.8px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4 * 0.8px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4 * 0.8px;
      scrollbar-arrow-color: red;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5 * 0.8px;
      -webkit-box-shadow: inset 0 0 5 * 0.8px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      scrollbar-arrow-color: red;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5 * 0.8px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .agreementtext {
    font-size: 16px;
    // text-indent: 2em;
    padding: 15 * 0.8px;
    br {
      text-indent: 2em;
    }
  }
  .tips {
    font-size: 18 * 0.8px;
    font-family: SimHei;
    font-weight: 400;
    line-height: 27 * 0.8px;
    color: #a8a8a8;
    text-align: center;
    margin-top: 20 * 0.8px;
    letter-spacing: 6 * 0.8px;
  }
  .confirm_btn_list {
    width: 720 * 0.8px;
    margin: 0 auto;
    margin-top: 20 * 0.8px;
    .confirm_btn {
      line-height: 62 * 0.8px;
      text-align: center;
      border-radius: 6 * 0.8px;
      font-size: 26 * 0.8px;
      cursor: pointer;
    }
    .cancel {
      width: 238 * 0.8px;
      height: 58 * 0.8px;
      border: 2 * 0.8px solid #ff7391;
      color: #ff7391;
    }
    .confirm {
      width: 242 * 0.8px;
      height: 62 * 0.8px;
      background: #ff7391;
      color: #fff;
      margin-left: 233 * 0.8px;
    }
  }
}
@media screen and (max-width:1199px) {
  .update_tips {
    font-size: 16px;
    font-weight: 700;
    color: #343434;
  }
  .agreementtext_box {
    width: 1260 * 0.6px;
    height: 400 * 0.6px;
    background: #fbfbfb;
    margin: 0 auto;
    // margin-top: 16 * 0.6px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4 * 0.6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4 * 0.6px;
      scrollbar-arrow-color: red;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5 * 0.6px;
      -webkit-box-shadow: inset 0 0 5 * 0.6px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      scrollbar-arrow-color: red;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5 * 0.6px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .agreementtext {
    font-size: 16px;
    // text-indent: 2em;
    padding: 15 * 0.6px;
    br {
      text-indent: 2em;
    }
  }
  .tips {
    font-size: 18 * 0.6px;
    font-family: SimHei;
    font-weight: 400;
    line-height: 27 * 0.6px;
    color: #a8a8a8;
    text-align: center;
    margin-top: 20 * 0.6px;
    letter-spacing: 6 * 0.6px;
  }
  .confirm_btn_list {
    width: 720 * 0.6px;
    margin: 0 auto;
    margin-top: 20 * 0.6px;
    .confirm_btn {
      line-height: 62 * 0.6px;
      text-align: center;
      border-radius: 6 * 0.6px;
      font-size: 26 * 0.6px;
      cursor: pointer;
    }
    .cancel {
      width: 238 * 0.6px;
      height: 58 * 0.6px;
      border: 2 * 0.6px solid #ff7391;
      color: #ff7391;
    }
    .confirm {
      width: 242 * 0.6px;
      height: 62 * 0.6px;
      background: #ff7391;
      color: #fff;
      margin-left: 233 * 0.6px;
    }
  }
}
</style>
